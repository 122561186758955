<template>
  <div>
    <a-result status="404" title="404" sub-title="Sayfa Bulunamadı, Bu sayfa kullanımdan kaldırılmış, silinmiş veya hiç mevcut değil">
      <template #extra>
        <router-link to="/" class="btn btn-outline-primary width-100">
          Geri Dön
        </router-link>
      </template>
    </a-result>
  </div>
</template>
<script>
export default {
  components: {
  },
}
</script>
